.emailInput {
  height: 56px;
  width: 100%;
  padding-left: 23px;
  outline: none;
  border: 1px solid #c9d4d9;
  box-sizing: border-box;
  border-radius: 100px;
  font-size: 16px;
  &::placeholder {
    font-size: 16px;
    color: #c9d4d9;
  }
}
