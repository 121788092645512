@import '@/scss/abstracts/variables.scss';
@import '@/scss/abstracts/functions.scss';
@import '@/scss/abstracts/mixins.scss';
@import '@/scss/components/tabs.scss';

.row {
  display: flex;
  align-items: center;
  column-gap: px_rem(10);
}
