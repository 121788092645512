@keyframes scaleBig {
  0% {
    transform: scale(0.6, 0.6);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes rotate360 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
.tabs {
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  position: relative;
  .tab-active-item {
    width: auto;
    height: 20px;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    margin-right: 50px;
    cursor: pointer;
    position: relative;
    color: #333333;
    .actline {
      width: 80px;
      height: 4px;
      background: #3ca7fa;
      position: absolute;
      left: 0px;
      top: 40px;
    }
  }
  .tab-item {
    width: auto;
    height: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #666666;
    line-height: 20px;
    margin-right: 50px;
    cursor: pointer;
    position: relative;
    .actline {
      width: 80px;
      height: 4px;
      background: #3ca7fa;
      position: absolute;
      left: 0px;
      top: 40px;
    }
  }
  .linkTab {
    position: absolute;
    top: 5px;
    right: 54px;
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #3ca7fa;
    line-height: 14px;
    cursor: pointer;
  }
  .btn-close {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 6px;
    top: 2px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
      animation: rotate360 1s;
    }
  }
}

.buttons {
  .send-sms-button {
    display: inline-block;
    width: max-content;
    height: 56px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    background-color: #1169f7;
    border-radius: 100px;
    padding-inline: 16px;
    :hover {
      opacity: 0.8;
    }
    &.countdown-active {
      background-color: #c9d4d9;
    }
  }
}

.box-mobile {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #272a2d;
  strong {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #272a2d;
    display: block;
    padding-top: 8px;
  }
}

.password {
  &__verification {
    > div {
      &:first-child {
        flex: 1 1 100%;
      }
    }
  }
}
