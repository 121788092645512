.category-box {
  width: 150px;
  float: left;
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid #d8d8d8;
  border-radius: 3px;
  .category-item {
    width: 100%;
    height: auto;
    float: left;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    font-size: 15px;
    line-height: 15px;
    color: #333333;
  }
  .category-act-item {
    width: 100%;
    height: auto;
    float: left;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    font-size: 15px;
    line-height: 15px;
    background-color: rgba(60, 167, 250, 0.1);
    color: #333333;
  }
}

.image-list-box {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 0;
  display: grid;
  row-gap: 27px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  .image-item {
    width: 100%;
    min-height: 142px;
    background: #f4fafe;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    border: 2px solid transparent;
    display: flex;
    flex-direction: column;
    .sel {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      margin: auto;
      width: 30px;
      height: 30px;
    }

    .image-render,
    .image-name {
      width: 100%;

      .image-view {
        display: flex;
        align-items: center;
        width: 100%;
        height: 110px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        overflow: hidden;
      }
    }
    .image-render {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-name {
      width: 100%;
      height: auto;
      padding: 10px 5px;
      box-sizing: border-box;
      .name {
        font-size: 10px;
        font-weight: 400;
        color: #333333;
        line-height: 15px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:hover {
      background: #fff;
      border: 2px solid #3ca7fa;
      .image-name,
      .image-render {
        background-color: rgba(60, 167, 250, 0.2);
      }
    }
  }

  .image-active-item {
    border: 2px solid #3ca7fa;
  }
}
