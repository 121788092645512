.header {
  width: 100%;
  height: auto;
  float: left;
  box-sizing: border-box;
  padding: 0px 0px 20px 0px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.body {
  width: 100%;
  height: 575px;
  overflow-x: hidden;
  overflow-y: auto;
  float: left;
  box-sizing: border-box;
}
