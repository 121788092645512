.passwordInput {
  display: inline-flex;
  line-height: 1.5714285714285714;
  height: 56px;
  width: 100%;
  padding: unset;
  padding-left: 23px;
  padding-right: 11px;
  border: 1px solid #c9d4d9;
  box-sizing: border-box;
  border-radius: 100px;
  font-size: 16px;
  outline: none;
  input {
    border: none;
  }
  &::placeholder {
    font-size: 16px;
    color: #c9d4d9;
  }
  &:global(.ant-input-affix-wrapper) {
    border-radius: 100px;
  }
}
