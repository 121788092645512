@import '@/scss/abstracts/variables.scss';
@import '@/scss/abstracts/functions.scss';
@import '@/scss/abstracts/mixins.scss';
@import '@/scss/components/tabs.scss';

.buttonRows {
  --leftSideMenuWidth: 320px;
  position: fixed;
  bottom: 0;
  left: var(--leftSideMenuWidth);
  right: 0;
  z-index: z-index(buttonRows);
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  background-color: white;
  display: flex;
  box-shadow: 0px -2px 4px 0px rgba(102, 102, 102, 0.05);
  column-gap: px_rem(10);
}
