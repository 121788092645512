@import '@/scss/abstracts/variables.scss';
@import '@/scss/abstracts/functions.scss';
@import '@/scss/abstracts/mixins.scss';
@import '@/scss/components/tabs.scss';

.imageItem {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-color: #f6f6f6;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  align-items: center;
  display: flex;
  width: 100%;
  &:hover {
    opacity: 0.8;
    .checkbox {
      display: block;
    }
  }

  .checkbox {
    display: none;
    width: 20px;
    height: 20px;
    background: rgba(#ffffff, 0.6);
    border-radius: 3px;
    border: 2px solid #d9d9d9;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 100;
    cursor: pointer;
  }

  .checked {
    width: 20px;
    height: 20px;
    background: var(--primaryTextColor);
    border-radius: 3px;
    border: 2px solid var(--primaryTextColor);
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
  }
}
